<template>
  <modal class-name="w-11/12 sm:w-4/12" ref="update-profile" @close="close">
    <h1 class="text-xl text-left font-bold mb-5">
      Update {{ businessName }} Profile
    </h1>
    <form @submit.prevent="updateMerchantProfile">
      <template v-if="getFormError(updateProfile)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(updateProfile) }}</span>
        </div>
      </template>

      <div class="mb-8 flex flex-row">
        <label class="custom-switch">
          <input
            type="checkbox"
            class="custom-switch-input"
            v-model="updateProfile.data.spread_enabled.value"
          />
          <span class="custom-switch-label"></span>
        </label>
        <span class="text-sm font-semibold mt-1 ml-1">
          {{ isSpreadPaymentEnabled ? 'Disable' : 'Enable' }} Spread Payment
        </span>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <form-group
          name="business_name"
          :form="updateProfile"
          v-model="updateProfile.data.business_name.value"
        >
          Business Name
        </form-group>

        <form-group
          name="email"
          :form="updateProfile"
          v-model="updateProfile.data.email.value"
        >
          Email
        </form-group>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <form-group
          type="select"
          :options="industryOptions"
          name="industry_id"
          :form="updateProfile"
          v-model="updateProfile.data.industry_id.value"
        >
          Industry
        </form-group>

        <form-group
          type="phone"
          name="phone"
          :form="updateProfile"
          v-model="updateProfile.data.phone.value"
        >
          Phone Number
        </form-group>
      </div>

      <form-group
        name="address"
        :form="updateProfile"
        v-model="updateProfile.data.address.value"
      >
        Address
      </form-group>

      <form-group
        name="description"
        :form="updateProfile"
        v-model="updateProfile.data.description.value"
      >
        Description
      </form-group>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <form-group
          type="select"
          :options="states"
          name="state"
          :form="updateProfile"
          v-model="updateProfile.data.state.value"
        >
          State
        </form-group>

        <form-group
          type="select"
          :options="lgas"
          name="lga"
          :form="updateProfile"
          v-model="updateProfile.data.lga.value"
        >
          LGA
        </form-group>
      </div>

      <form-group
        name="website"
        :form="updateProfile"
        v-model="updateProfile.data.website.value"
      >
        Website
      </form-group>

      <div class="flex justify-center">
        <button
          class="btn btn-blue w-full font-normal"
          type="submit"
          :disabled="updateProfile.loading"
        >
          <sm-loader-white v-if="updateProfile.loading" />
          <span v-else>Submit</span>
        </button>
      </div>
    </form>
  </modal>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updateProfile: this.$options.basicForm([
        { name: 'business_name', rules: 'required' },
        { name: 'address', rules: 'required' },
        { name: 'email', rules: 'required|email' },
        { name: 'state', rules: 'nullable' },
        { name: 'lga', rules: 'nullable' },
        { name: 'industry_id', rules: 'required' },
        { name: 'website', rules: 'nullable' },
        { name: 'phone', rules: 'required|phone' },
        { name: 'description', rules: 'nullable' },
        { name: 'spread_enabled', rules: 'nullable' }
      ]),
      industries: this.$options.resource([])
    };
  },
  beforeMount() {
    this.getIndustries();
  },
  mounted() {
    this.mapProfileData();
    this.$refs['update-profile']?.open();
  },
  computed: {
    ...mapState(['states']),
    businessName() {
      return this.profile?.business_name;
    },
    industry() {
      return this.profile?.industry?.name;
    },
    industryOptions() {
      return this.industries?.data?.length
        ? this.industries.data?.map(industry => ({
            title: industry.name,
            value: industry.id
          }))
        : [];
    },
    states() {
      return this.$store.state.states?.map(state => state.name);
    },
    lgas() {
      return (
        this.$store.state.states
          ?.find(state => state.name === this.updateProfile.data.state.value)
          ?.locals.map(lga => lga.name) || []
      );
    },
    isSpreadPaymentEnabled() {
      return this.updateProfile.data.spread_enabled.value;
    }
  },
  watch: {
    'industries.data'(value) {
      this.updateProfile.data.industry_id.value = value?.find(
        industry => industry?.id === this.profile?.industry?.id
      )?.id;
    }
  },
  methods: {
    mapProfileData() {
      for (let key in this.updateProfile.data) {
        this.updateProfile.data[key].value =
          this.profile[key] || this.profile?.descriptions[key] || '';
      }
    },
    close() {
      this.$parent?.closeProfile();
    },
    async getIndustries() {
      this.industries.loading = true;
      await this.sendRequest('admin.merchants.category', {
        success: response => (this.industries.data = response.data.data),
        error: error => console.log(error)
      });
      this.industries.loading = false;
    },
    async updateMerchantProfile() {
      this.updateProfile.loading = true;
      await this.sendRequest(
        'admin.merchants.updateProfile',
        this.profile?.id,
        {
          data: {
            merchant_id: this.profile?.id,
            ...this.getFormData(this.updateProfile)
          },
          success: () => {
            this.$refs['update-profile']?.close();
            this.$parent?.getMerchant();
          },
          error: error => (this.updateProfile.error = error)
        }
      ).finally(() => (this.updateProfile.loading = false));
    }
  }
};
</script>
